import axios from "axios"

const api = axios.create({
    baseURL: process.env.VUE_APP_NPS_API_URL,
})

api.interceptors.request.use(function (request) {
    request.headers.common["Authorization"] = "Bearer " + localStorage.getItem("ACCESS_TOKEN")
    request.headers.common["Accept"] = "application/json"

    return request
}, function (error) {
    console.log(error)
    return Promise.reject(error)
})

export default api