import {validaToken} from "@/support/token"
import {criaDivVue} from "@/support/gerenciaElementosDom"
import {carregaVueApp} from "@/support/carregaVue"
import {enventoAppErro, enventoAppFechado} from "@/api/eventosExternos"

export function iniciaApp(widgetActions) {
    if (widgetActions) {
        window.localStorage.setItem("WIDGET_ACTIONS", JSON.stringify(widgetActions))

        let usuarioRespondeu = window.localStorage.getItem("USUARIO_RESPONDEU")
        let novoRespondente = false
        let novaVerificacao = null

        let responderDepois = window.localStorage.getItem("RESPONDER_DEPOIS")
        if (responderDepois) {
            responderDepois = new Date(responderDepois)
        }

        const responderDepoisDataValida = responderDepois instanceof Date && !isNaN(responderDepois)

        if (usuarioRespondeu) {
            usuarioRespondeu = JSON.parse(usuarioRespondeu)

            novoRespondente = usuarioRespondeu.RESPONDENTE_ID !== widgetActions.NPS_RESPONDENTE_ID
            novaVerificacao = new Date() > new Date(usuarioRespondeu.DATA_VERIFICACAO)

            // Não é um novo respondente e ainda não está apta a realizar nova pesquisa
            if (!novoRespondente && !novaVerificacao) {
                enventoAppFechado()
                return false
            }
        }

        let responderDepoisRespondenteId = window.localStorage.getItem("ULTIMO_RESPONDENTE_ID")
        // É um novo respondente que logou no sistema?
        if (responderDepoisRespondenteId) {
            if (parseInt(responderDepoisRespondenteId) !== widgetActions.NPS_RESPONDENTE_ID){
                window.localStorage.setItem("ULTIMO_RESPONDENTE_ID", widgetActions.NPS_RESPONDENTE_ID)
                novoRespondente = true
            }
        }

        // Respondente solicitou para responder depois
        if (responderDepoisDataValida && (responderDepois > new Date()) && !novoRespondente) {
            enventoAppFechado()
            return false
        }

        validaToken().then(() => {
            window.localStorage.removeItem("RESPONDER_DEPOIS")
            window.localStorage.removeItem("ULTIMO_RESPONDENTE_ID")
            window.localStorage.removeItem("USUARIO_RESPONDEU")
            criaDivVue()
            carregaVueApp()
        }
        ).catch((erro) => {
            console.error(erro)
            enventoAppErro()
        })
    } else {
        //COMUNICAR CLIENTE PARA REMOVER WIDGET
        enventoAppErro()
    }
}