import {createApp} from "vue"
import App from "@/App"
import "@/assets/sass/base.scss"
import { library } from "@fortawesome/fontawesome-svg-core"
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

library.add(faCheckCircle, faTimes, faExternalLinkAlt)

export function carregaVueApp(){
    let NPSWidget = document.getElementById("NPSWidget")
    createApp(App)
        .component("font-awesome-icon", FontAwesomeIcon)
        .mount(NPSWidget)
}