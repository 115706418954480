<template>
    <div class="nps-painel-item wrapper">
        <div class="box">
            <form
                method="post"
                @submit.prevent>
                <div>
                    <div class="nps-painel-center  nps-painel-text">
                        <h3 class=" nps-text-white">Em uma escala de 1 a 10, qual a probabilidade de você recomendar o Bússola para um amigo ou colega?</h3>
                    </div>
                    <NotaNPS
                        v-model="formData.nota"
                        class="nps-painel-center"
                        :items="notas"
                        @nota-selecionada="enviaNota"/>
                </div>
            </form>
        </div>
        <div class="box-left">
            <button
                class="nps-btn nps-btn-link"
                @click="$emit('respondeDepois')">
                Responder depois
            </button>
        </div>
    </div>
</template>

<script>
import {onMounted, reactive} from "vue"
import NotaNPS from "@/components/base/NotasNPS"
import {salvaRespostaAPI} from "@/services/Resposta"
import {storeUsuarioRespondeu} from "@/store/usuarioRespondeu"

export default {
    name: "SalvaNota",
    components: {
        NotaNPS,
    },
    props: {
        respondente_id: {
            type: Number,
        },
        dados: {
            type: Array,
        },
    },
    emits: [
        "notaEnviada",
        "respondeDepois",
    ],
    setup(props, context){
        const formData = reactive(
            {
                nota: null,
                respondente_id: props.respondente_id,
            }
        )
        const notas = reactive([])
        onMounted(() => {
            for (let i = 1; i < 11; i++) {
                notas.push({
                    id: i+1,
                    nota: i,
                    label: `${i}`,
                })
            }
        })

        async function enviaNota(value){
            formData.nota = value
            if (props.dados){
                formData.dados = JSON.stringify(props.dados)
            }
            let response = await salvaRespostaAPI(formData)
            if (response.status === 201){
                storeUsuarioRespondeu(props.respondente_id)
                context.emit("notaEnviada", {"nota": value, "resposta_id": response.data.id})
            }
        }

        return {
            formData,
            notas,
            enviaNota,
        }
    },
}
</script>