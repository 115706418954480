import api from "@/services/api/apiClient"

export function salvaRespostaAPI (payload) {
    return api.post("api/resposta/", payload)
}

export function EditaRespostaAPI ($payload, $respostaId) {
    return api.patch(`api/resposta/${$respostaId}/nota`, $payload)
}

export function salvaComentarioAPI ($payload, $respostaId) {
    return api.post(`api/resposta/${$respostaId}/comentario`, $payload)
}