<template>
    <div class="nps-mensagem">
        <div class="grid grid-columns-2-mensagem">
            <div class="grid-column-item text-center ml-3">
                <h2 class="nps-text-white mt-2">
                    Obrigado pela participação
                    <small
                        v-if="nota_enviada >= 9"
                        class="span-text">
                        Você conhece uma OSC que poderia usar o Bússola?
                        <a
                            href="https://bit.ly/42kVP68"
                            target="_blank">
                            Indique agora
                        </a> e receba até R$ 300 por indicação.
                    </small>
                    <small
                        v-else
                        class="span-text">
                        Saber a sua opinião é muito importante para nós.
                    </small>
                </h2>
            </div>
            <div class="grid-column-item ml-1">
                <button
                    class="nps-btn nps-btn-close"
                    type="button"
                    @click="$emit('pesquisaFinalizada')">
                    <font-awesome-icon
                        class="nps-text-white"
                        :icon="['fa', 'times']"
                        size="lg" />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import {onBeforeMount} from "vue"
import {enventoAppFechado} from "@/api/eventosExternos"

export default {
    name: "Mensagem",
    props:{
        nota_enviada: {
            type: Number,
        },
    },
    emits:[
        "pesquisaFinalizada",
    ],
    setup(props, context){
        function fechaMensagem(){
            setTimeout(function () {
                context.emit("pesquisaFinalizada")
                enventoAppFechado()
            }, 45000)
        }
        onBeforeMount(() => {
            fechaMensagem()
        })
    },
}
</script>