<template>
    <div class="nps-radio">
        <div
            v-for='nota in 10'
            :key='nota'>
            <label
                :class="['nps-horizontal-inline', comparaNotaPadrao(nota)]">
                <input
                    :value="nota"
                    type="radio"
                    name="options"
                    @click="selecionaNota"
                >
                {{ nota }}
            </label>
        </div>
    </div>
</template>

<script>
import {ref} from "vue"

export default {
    name: "NotasNPS",
    props: {
        nota_padrao: {
            "type": Number,
            "default": 0,
        },
    },
    emits: [
        "nota-selecionada",
        "atualiza:nota",
    ],
    setup(props, context) {
        const nota = ref()
        let notaPadrao = props.nota_padrao

        function comparaNotaPadrao(nota){
            if (nota === notaPadrao){
                return "nps-radio-checked"
            }
        }

        function selecionaNota(event) {
            context.emit("nota-selecionada", event.target.value)
        }

        return {
            nota,
            selecionaNota,
            comparaNotaPadrao,
        }
    },
}
</script>

<style>

</style>