/** Funções que podem ser invocadas como eventos fora do widget **/

function postMessage(action){
    parent.postMessage({action: action}, "*")
}

/**
 * Lança um evento para avisar que o componente foi carregado. Chamado no onMounted do App.vue
 */
export function enventoAppCarregado (){
    postMessage("carregado")
}

/**
 * Lança um evento para avisar que o usuário já respondeu e que o compomente pode ser destruído da execução.
 */
export function enventoAppFechado (){
    postMessage("fechar_app")
}

/**
 * lança uma evento quando um erro ocorrer
 */
export function enventoAppErro (){
    postMessage("erro_no_app")
}