<template>
    <div>
        <form
            method="post"
            @submit.prevent>
            <div>
                <div class="nps-painel-center  nps-painel-text">
                    <h3 class=" nps-text-white">Em uma escala de 1 a 10, qual a probabilidade de você recomendar o Bússola para um amigo ou colega?</h3>
                </div>
                <NotaNPS
                    v-model="formData.nota"
                    class="nps-painel-center"
                    :items="notas"
                    :nota_padrao="nota_enviada"
                    @nota-selecionada="editaNota"/>
            </div>
        </form>
        <div class="box-left">
            <button
                class="nps-btn nps-btn-link"
                @click="abreComentario">
                Voltar
            </button>
        </div>
    </div>
</template>

<script>
import {onMounted, reactive} from "vue"
import NotaNPS from "@/components/base/NotasNPS"
import {EditaRespostaAPI} from "@/services/Resposta"

export default {
    name: "EditaNota",
    components: {
        NotaNPS,
    },
    props: {
        nota_enviada: {
            type: Number,
        },
        respondente_id: {
            type: Number,
        },
        resposta_id: {
            type: Number,
        },
    },
    emits: [
        "abreComentario",
    ],
    setup(props, context){
        const formData = reactive(
            {
                nota: null,
                respondente_id: props.respondente_id,
            }
        )
        const notas = reactive([])

        onMounted(() => {
            for (let i = 1; i < 11; i++) {
                notas.push({
                    id: i+1,
                    nota: i,
                    label: `${i}`,
                })
            }
        })

        function abreComentario (){
            context.emit("abreComentario", {"nota": props.nota_enviada, "resposta_id": props.resposta_id})
        }

        async function editaNota(value){
            formData.nota = value
            try {
                await EditaRespostaAPI(formData, props.resposta_id)
                context.emit("abreComentario", {"nota": value, "resposta_id": props.resposta_id})
            } catch (e) {
                console.log(e)
            }
        }

        return {
            formData,
            notas,
            editaNota,
            abreComentario,
        }
    },
    data: function () {
        return {}
    },
}
</script>