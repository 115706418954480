export function storeUsuarioRespondeu (NPS_RESPONDENTE_ID) {
    let verificarEmDias = 10
    let dataNovaVerificacao = new Date()
    dataNovaVerificacao.setHours(dataNovaVerificacao.getHours() + (24 * verificarEmDias))

    let usuarioRespondeu = {
        RESPONDENTE_ID: NPS_RESPONDENTE_ID,
        DATA_VERIFICACAO: "" + dataNovaVerificacao,
    }
    window.localStorage.setItem("USUARIO_RESPONDEU",JSON.stringify(usuarioRespondeu))
}