<template>
    <form
        method="post"
        @submit.prevent>
        <div class="box">
            <h3
                class="nps-text-white">
                O que te motivou nesta nota?
            </h3>
            <textarea
                id="comtentario"
                v-model="formData.comentario"
                class="nps-textarea"
                name="comtentario"
                rows="3">
            </textarea>
            <b class="nps-text-white">
                {{ formErro.erro }}
            </b>
        </div>
        <div class="grid grid-columns-3-button mr-1 ml-1">
            <div class="grid-column-item">
                <button
                    class="nps-btn nps-btn-link float-left"
                    type="button"
                    @click="editaNota">
                    Alterar nota
                </button>
            </div>
            <div class="grid-column-item  mr-3">
                <button
                    class="nps-btn nps-btn-link float-right"
                    type="button"
                    @click="$emit('comentarioEnviado')">
                    Fechar
                </button>
            </div>
            <div class="grid-column-item">
                <button
                    class="nps-btn nps-btn-enviar float-right"
                    type="button"
                    @click="enviaComentario">
                    Enviar
                </button>
            </div>
        </div>
    </form>
</template>

<script>
import {reactive} from "vue"
import { salvaComentarioAPI } from "@/services/Resposta"

export default {
    name: "SalvaComentario",
    props:{
        respondente_id: {
            type: Number,
        },
        resposta_id: {
            type: Number,
        },
    },
    emits: [
        "comentarioEnviado",
        "editaNota",
    ],
    setup(props, context) {
        const formData = reactive(
            {
                comentario: "",
                respondente_id: props.respondente_id,
            }
        )
        const formErro = reactive({erro: null})

        async function enviaComentario(){
            try {
                await salvaComentarioAPI(formData, props.resposta_id)
                context.emit("comentarioEnviado")
            } catch (e){
                formErro.erro = e.response.data.mensagem
            }
        }

        function editaNota() {
            context.emit("editaNota")
        }

        return {
            formErro,
            formData,
            editaNota,
            enviaComentario,
        }
    },
}
</script>