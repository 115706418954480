<template>
    <div
        v-if="!estado.fechada"
        class="align-bottom">
        <div
            v-if="!estado.mensagem"
            class="nps-painel">
            <div class="nps-painel-body pt-1 pb-1">
                <SalvaNota
                    v-if="estado.nota"
                    :respondente_id="respondenteId"
                    :dados="dados"
                    @nota-enviada="abreSalvaComentario"
                    @responde-depois="respondeDepois"
                />
                <SalvaComentario
                    v-else-if="estado.comentario"
                    :resposta_id="respostaId"
                    :respondente_id="respondenteId"
                    @edita-nota="abreEditaNota"
                    @comentario-enviado="abreMensagem"
                />
                <EditaNota
                    v-else-if="estado.editaNota"
                    :respondente_id="respondenteId"
                    :resposta_id="respostaId"
                    :nota_enviada="notaEnviada"
                    @abre-comentario="abreSalvaComentario"
                />
            </div>
        </div>
        <div v-if="estado.mensagem">
            <Mensagem
                :nota_enviada="notaEnviada"
                @pesquisa-finalizada="pesquisaFinalizada"
            />
        </div>
    </div>
</template>

<script>
import {onMounted, reactive, ref} from "vue"

import EditaNota from "@/components/resposta/EditaNota"
import SalvaComentario from "@/components/resposta/SalvaComentario"
import SalvaNota from "@/components/resposta/SalvaNota"
import Mensagem from "@/components/resposta/Mensagem"

import {respondentePodeResponderAPI} from "@/services/Respondente"
import {enventoAppCarregado, enventoAppFechado} from "@/api/eventosExternos"
import {storeUsuarioRespondeu} from "@/store/usuarioRespondeu"


export default {
    name: "App",
    components: {Mensagem, SalvaNota, SalvaComentario, EditaNota},
    setup() {
        const widgetActions = JSON.parse(window.localStorage.getItem("WIDGET_ACTIONS"))
        const respondeDepoisEmHoras = 24
        let estado = reactive({
            "nota": false,
            "comentario": false,
            "editaNota": false,
            "mensagem": false,
            "fechada": true,
        })
        let podeResponder = ref(false)
        let notaEnviada = ref(-1)
        let respostaId = ref(0)
        const respondenteId = widgetActions.NPS_RESPONDENTE_ID
        const dados = widgetActions.NPS_DADOS_ADICIONAIS

        onMounted(() => {
            iniciaComponente()
        })

        async function iniciaComponente() {
            podeResponder = await respondentePodeResponderAPI(respondenteId)
            if (podeResponder.data.pode_responder) {
                abreSalvaNota()
                enventoAppCarregado()
            } else {
                //COMUNICAR CLIENTE PARA REMOVER WIDGET
                storeUsuarioRespondeu(widgetActions.NPS_RESPONDENTE_ID)
                enventoAppFechado()
            }
        }

        function escondeTodosElementos() {
            estado.nota = false
            estado.comentario = false
            estado.editaNota = false
            estado.mensagem = false
            estado.fechada = false
        }

        function abreSalvaNota() {
            escondeTodosElementos()
            estado.nota = true
        }

        function abreEditaNota() {
            escondeTodosElementos()
            estado.editaNota = true
        }

        function abreSalvaComentario(values) {
            notaEnviada.value = parseInt(values.nota)
            respostaId.value = parseInt(values.resposta_id)
            escondeTodosElementos()
            estado.comentario = true
        }

        function abreMensagem() {
            escondeTodosElementos()
            estado.mensagem = true
        }

        function fechaPesquisa() {
            escondeTodosElementos()
            estado.fechada = true
        }

        function respondeDepois() {
            fechaPesquisa()
            let dataRespondeDepois = new Date()
            dataRespondeDepois.setHours(dataRespondeDepois.getHours() + parseInt(respondeDepoisEmHoras))
            window.localStorage.setItem("RESPONDER_DEPOIS", dataRespondeDepois)
            window.localStorage.setItem("ULTIMO_RESPONDENTE_ID", respondenteId)
            enventoAppFechado()
        }

        function pesquisaFinalizada (){
            escondeTodosElementos()
            estado.fechada = true
            //COMUNICAR CLIENTE PARA REMOVER WIDGET
        }


        return {
            estado,
            notaEnviada,
            respondenteId,
            respostaId,
            dados,
            respondeDepoisEmHoras,
            podeResponder,
            pesquisaFinalizada,
            iniciaComponente,
            abreEditaNota,
            abreSalvaComentario,
            abreMensagem,
            respondeDepois,
        }
    },
}
</script>
