import {autenticarAPI, estaAutenticadoAPI} from "@/services/PassportAuth"

export async function validaToken() {
    let expiraEm = new Date(window.localStorage.getItem("EXPIRA_EM"))
    let accessToken = window.localStorage.getItem("ACCESS_TOKEN")

    if (!accessToken || !expiraEm) {
        await registraToken()
        return true
    }
    if (expiraEm){
        if (new Date() > expiraEm) {
            await registraToken()
            return true
        }
    }
    if (accessToken) {
        try {
            await estaAutenticadoAPI()
            return true
        } catch (e){
            if (e.response.status === 401) {
                await registraToken()
            }
        }
    }
}

async function registraToken(){
    const widgetActions = JSON.parse(window.localStorage.getItem("WIDGET_ACTIONS"))
    let payload = {
        "client_id": widgetActions.NPS_CLIENT_ID,
        "client_secret": widgetActions.NPS_SECRET,
        "grant_type": "client_credentials",
        "scope": "*",
    }
    let responseAuth = await autenticarAPI(payload)
    let expiraEm = new Date()
    expiraEm.setSeconds(expiraEm.getSeconds() + parseInt(responseAuth.data.expires_in))
    window.localStorage.setItem("ACCESS_TOKEN", responseAuth.data.access_token)
    window.localStorage.setItem("EXPIRA_EM", expiraEm)
}